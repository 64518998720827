
/*NAVBAR*/
nav{
	&.navbar.navbar-fixed-top{
    height: 70px;
    padding: 10px 0;
		border-color: transparent;
    background-color: white;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 13px 18px 0 rgba(0,0,0,.14);
    @include transition;
		&.navbar-shrink{
	    height: 50px;
      padding: 0;
	    background-color: white;
	    background-color: rgba(255, 255, 255, 1);
	    .navbar-header{
				.navbar-brand{
			    &.brand-img{
			    	img{
					    width: 60px;
			    	}
			    }
				}
	    }
		}
	}
	.navbar-header{
    max-height: 55px;
    position: relative;
    width: 100%;
		.navbar-brand{
			cursor: pointer;
	    padding: 4px 0px 8px;
	    &.brand-img{
	    	img{
			    width: 80px;
			    top: 50%;
			    left: 50%;
			    display: block;
			    position: absolute;
			    -webkit-transform: translate(-50%,-50%);
			    transform: translate(-50%,-50%);
			    @include transition;
	    	}
	    }
		}
		.navbar-toggle {
	    border-radius: 0;
	    position: absolute;
	    top: 9px;
	    left: 0;
      padding: 10px 0;
	    margin: 0;
      min-height: 33px;
	    display: block;
	    span{
	    	background-color: #000;
	    	height: 1px;
  	    width: 23px;
	    }
		}
	}
	#navbar{
		.navbar-nav {
			margin: 0;
		    >li{
		    	margin-left: 0px;
	    	    border-right: 1px solid #9e9e9e;
		    	&:nth-child(1){
		    		margin-left: 0px;
		    	    border-left: 1px solid #9e9e9e;
		    	}
				a{
				    font-size: 12px;
					&:hover,
					&:focus{
					    background-color: transparent !important;
					}
				}
		    }
		    li.active {
			    margin-bottom: 2px;
			    border-bottom: 3px solid #6bb5c1;
			}
			li.active > a{
		    font-family: $primary-BdI;
        border-bottom: 3px solid #6bb5c1;
		    margin-bottom: -3px;
			}
		}
	}			
}

#wrapper{
	position: relative;
  padding-left: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
	#sidebar-wrapper {
    width: 0;
    position: fixed;
    left: 200px;
    top: 0;
    bottom: 0px;
    z-index: 222;
    padding-top: 50px;
    margin-left: -200px;
    overflow: hidden;
    background: #eae7e1;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    .sidebar-nav{
    	margin-top: 21px;
	    padding: 0;
	    li {
		    text-indent: 20px;
		    a {
			    display: block;
	        font-size: 12px;
	        text-transform: uppercase;
			    color: #483028;
			    font-family: $primary-Bk;
			    padding: 12px 0px 10px 15px;
			    -webkit-text-decoration: none;
			    text-decoration: none;
			    white-space: nowrap;
			    text-overflow: ellipsis;
			    overflow: hidden;
			    &:hover {
			    	-webkit-text-decoration: none;
				    text-decoration: none;
				    background-color: rgba(0, 0, 0, 1);
		        background-color: #c1c1c1;
					}
				}
			}
  	}
		.social-block{
	    position: absolute;
	    margin-top: 0;
	    bottom: 10px;
      max-height: 29px;
	    overflow: hidden;
	    display: none;
		}
	}
	.sidebar-closebox {
    position: fixed;
    top: 0;
    bottom: 0px;
    right: 0px;
    left: 0px;
    opacity: 0;
    background-color: transparent;
    background-color: rgba(0, 0, 0, .8);
    display: none;
    z-index: 170;
	}
	&.sidebar-toggle{
		nav{
			&.navbar.navbar-fixed-top{
		    background-color: rgba(255, 255, 255, 1);
			}
		}
		#sidebar-closebox {
	    display: block;
	    opacity: 1;
		}
		#sidebar-wrapper {
	    width: 270px;
	    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
	    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
	    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
		}
		#page-content-wrapper{
			.navbar-toggle{
				.icon-bar + .icon-bar {
			    margin-top: 0;
				}
				.icon-bar{
			    height: 2px;
					&:nth-child(1){
				    -webkit-transform: rotate(-45deg) translate(0px, 0px);
				    transform: rotate(-45deg) translate(0px, 0px);
					}
					&:nth-child(2){
		        opacity: 0;
		        display: none;
					}
					&:nth-child(3){
				    -webkit-transform: rotate(45deg) translate(-15px, -15px);
				    transform: rotate(45deg) translate(0px, 0px);
					}
				}
			}
		}
	}
}

// RESPONSIVE

/*NAVBAR*/
@media (min-width: 768px) {
	#wrapper{
		&.sidebar-toggle{
			#sidebar-wrapper {
		    width: 350px;
			}
		}
	}
}

@media (min-width: 992px) {}
@media (min-width: 1200px) {}



