// -------------------------
// LOADERS & SPINNERS
// -------------------------

// Loader 01 ///////////////
// .loader{position: absolute; z-index: 999; left: 0; top: 0; bottom: 0; right: 0; background-color: #fafafa; 
//   img{position: fixed; left: 50%; top: 50%; @include transform(translate(-50%, -50%));}
// }

// <div class="loader" id="loader">
//   <img src="images/ajax-loader.gif" alt="">
// </div>

// Loader 02 ///////////////
// .loader {position: absolute; top: 0; left: 0; right: 0; bottom: 0; height: 100%; width: 100%; z-index: 8080;}
// .loader-inner {position: absolute; top: 50%; left: 50%; @include transform(translate(-50%, -50%)); color: #fff;}
// .loader-inner label {font-size: 20px; opacity: 0; display:inline-block; }
// @include keyframes(lol) {
//   0% {opacity: 0; transform: translateX(-300px);}
//   33% {opacity: 1; transform: translateX(0px);}
//   66% {opacity: 1; transform: translateX(0px);}
//   100% {opacity: 0; transform: translateX(300px);}
// }
// .loader-inner label:nth-child(6) {-webkit-animation: lol 3s infinite ease-in-out; animation: lol 3s infinite ease-in-out;}
// .loader-inner label:nth-child(5) {-webkit-animation: lol 3s 100ms infinite ease-in-out; animation: lol 3s 100ms infinite ease-in-out;}
// .loader-inner label:nth-child(4) {-webkit-animation: lol 3s 200ms infinite ease-in-out; animation: lol 3s 200ms infinite ease-in-out;}
// .loader-inner label:nth-child(3) {-webkit-animation: lol 3s 300ms infinite ease-in-out; animation: lol 3s 300ms infinite ease-in-out;}
// .loader-inner label:nth-child(2) {-webkit-animation: lol 3s 400ms infinite ease-in-out; animation: lol 3s 400ms infinite ease-in-out;}
// .loader-inner label:nth-child(1) {-webkit-animation: lol 3s 500ms infinite ease-in-out; animation: lol 3s 500ms infinite ease-in-out;}

// <div class="loader">
//   <div class="loader-inner"><label> ●</label> <label> ●</label> <label> ●</label> <label> ●</label> <label> ●</label> <label> ●</label></div>
// </div>


// Loader 03 ///////////////
.loader {position: fixed; top: 0; left: 0; right: 0; bottom: 0; height: 100%; width: 100%; z-index: 8080; overflow:hidden; background-color: $background;} 
.loader-3d {left: 50%; top: 50%; @include transform(translate(-50%, -50%));}
.loader-3d, .loader-3d:before, .loader-3d:after {width: 200px; height: 40px; background: no-repeat; background-image: radial-gradient(closest-side, $primary 100%, transparent), radial-gradient(closest-side, $primary 100%, transparent); background-size: 20px 20px, 20px 20px; background-position: 0% center, 100% center; position: absolute; animation: slide 1.5s cubic-bezier(.5,0,.5,1) infinite, zoom ease-out .75s infinite alternate; content: ''; border-radius:50%;}
.loader-3d {animation-delay: .5s; }
.loader-3d:after {animation-delay: 1s; }

@keyframes slide {to {background-position: 100%, 0;} }
@keyframes zoom {to {background-size: 40px 40px, 10px 10px;} }
// <div class="loader">
//   <div class="loader-3d"></div>
// </div>






