// -------------------------
// ANIMATIONS
// -------------------------

// http://www.justinaguilar.com/animations
// https://daneden.github.io/animate.css/

/*slideDown*/
.slideDown{
    animation-name: slideDown;
    -webkit-animation-name: slideDown;  
    animation-duration: 0.8s; 
    -webkit-animation-duration: 0.8s;
    animation-timing-function: ease;    
    -webkit-animation-timing-function: ease;    
    visibility: visible !important;                     
}

@keyframes slideDown {
    0% {transform: translateY(-60%);}
    50%{transform: translateY(8%);}
    65%{transform: translateY(-4%);}
    80%{transform: translateY(4%);}
    95%{transform: translateY(-2%);}           
    100% {transform: translateY(0%);}       
}

@-webkit-keyframes slideDown {
    0% {-webkit-transform: translateY(-60%);}
    50%{-webkit-transform: translateY(8%);}
    65%{-webkit-transform: translateY(-4%);}
    80%{-webkit-transform: translateY(4%);}
    95%{-webkit-transform: translateY(-2%);}           
    100% {-webkit-transform: translateY(0%);}   
}

/*slideUp*/
.slideUp{
    animation-name: slideUp;
    -webkit-animation-name: slideUp;    
    animation-duration: 1s; 
    -webkit-animation-duration: 1s;
    animation-timing-function: ease;    
    -webkit-animation-timing-function: ease;
    visibility: visible !important;         
}

@keyframes slideUp {
    0% {transform: translateY(100%);}
    50%{transform: translateY(-8%);}
    65%{transform: translateY(4%);}
    80%{transform: translateY(-4%);}
    95%{transform: translateY(2%);}
    100% {transform: translateY(0%);}   
}

@-webkit-keyframes slideUp {
    0% {-webkit-transform: translateY(100%);}
    50%{-webkit-transform: translateY(-8%);}
    65%{-webkit-transform: translateY(4%);}
    80%{-webkit-transform: translateY(-4%);}
    95%{-webkit-transform: translateY(2%);}
    100% {-webkit-transform: translateY(0%);}   
}

/*slideLeft*/
.slideLeft{
    animation-name: slideLeft;
    -webkit-animation-name: slideLeft;  
    animation-duration: 1s; 
    -webkit-animation-duration: 1s;
    animation-timing-function: ease-in-out; 
    -webkit-animation-timing-function: ease-in-out;     
    visibility: visible !important; 
}

@keyframes slideLeft {
    0% {transform: translateX(150%);}
    50%{transform: translateX(-8%);}
    65%{transform: translateX(4%);}
    80%{transform: translateX(-4%);}
    95%{transform: translateX(2%);}
    100% {transform: translateX(0%);}
}

@-webkit-keyframes slideLeft {
    0% {-webkit-transform: translateX(150%);}
    50%{-webkit-transform: translateX(-8%);}
    65%{-webkit-transform: translateX(4%);}
    80%{-webkit-transform: translateX(-4%);}
    95%{-webkit-transform: translateX(2%);}
    100% {-webkit-transform: translateX(0%);}
}

/*slideRight*/
.slideRight{
    animation-name: slideRight;
    -webkit-animation-name: slideRight; 
    animation-duration: 1s; 
    -webkit-animation-duration: 1s;
    animation-timing-function: ease-in-out; 
    -webkit-animation-timing-function: ease-in-out;     
    visibility: visible !important; 
}

@keyframes slideRight {
    0% {transform: translateX(-150%);}
    50%{transform: translateX(8%);}
    65%{transform: translateX(-4%);}
    80%{transform: translateX(4%);}
    95%{transform: translateX(-2%);}
    100% {transform: translateX(0%);}   
}

@-webkit-keyframes slideRight {
    0% {-webkit-transform: translateX(-150%);}
    50%{-webkit-transform: translateX(8%);}
    65%{-webkit-transform: translateX(-4%);}
    80%{-webkit-transform: translateX(4%);}
    95%{-webkit-transform: translateX(-2%);}
    100% {-webkit-transform: translateX(0%);}
}

/*slideExpandUp*/
.slideExpandUp{
    animation-name: slideExpandUp;
    -webkit-animation-name: slideExpandUp;  
    animation-duration: 1.6s;   
    -webkit-animation-duration: 1.6s;
    animation-timing-function: ease-out;    
    -webkit-animation-timing-function: ease -out;
    visibility: visible !important; 
}

@keyframes slideExpandUp {
    0% {transform: translateY(100%) scaleX(0.5);}
    30%{transform: translateY(-8%) scaleX(0.5);}   
    40%{transform: translateY(2%) scaleX(0.5);}
    50%{transform: translateY(0%) scaleX(1.1);}
    60%{transform: translateY(0%) scaleX(0.9);}
    70% {transform: translateY(0%) scaleX(1.05);}
    80%{transform: translateY(0%) scaleX(0.95);}
    90% {transform: translateY(0%) scaleX(1.02);}   
    100%{transform: translateY(0%) scaleX(1);}
}

@-webkit-keyframes slideExpandUp {
    0% {-webkit-transform: translateY(100%) scaleX(0.5);}
    30%{-webkit-transform: translateY(-8%) scaleX(0.5);}   
    40%{-webkit-transform: translateY(2%) scaleX(0.5);}
    50%{-webkit-transform: translateY(0%) scaleX(1.1);}
    60%{-webkit-transform: translateY(0%) scaleX(0.9);}
    70% {-webkit-transform: translateY(0%) scaleX(1.05);}
    80%{-webkit-transform: translateY(0%) scaleX(0.95);}
    90% {-webkit-transform: translateY(0%) scaleX(1.02);}   
    100%{-webkit-transform: translateY(0%) scaleX(1);}
}

/*expandUp*/
.expandUp{
    animation-name: expandUp;
    -webkit-animation-name: expandUp;   
    animation-duration: 0.7s;   
    -webkit-animation-duration: 0.7s;
    animation-timing-function: ease;    
    -webkit-animation-timing-function: ease;        
    visibility: visible !important; 
}

@keyframes expandUp {
    0% {transform: translateY(100%) scale(0.6) scaleY(0.5);}
    60%{transform: translateY(-7%) scaleY(1.12);}
    75%{transform: translateY(3%);}   
    100% {transform: translateY(0%) scale(1) scaleY(1);}   
}

@-webkit-keyframes expandUp {
    0% {-webkit-transform: translateY(100%) scale(0.6) scaleY(0.5);}
    60%{-webkit-transform: translateY(-7%) scaleY(1.12);}
    75%{-webkit-transform: translateY(3%);}   
    100% {-webkit-transform: translateY(0%) scale(1) scaleY(1);}   
}

/*fadeIn*/

.fadeIn{
    animation-name: fadeIn;
    -webkit-animation-name: fadeIn; 
    animation-duration: 1.5s;   
    -webkit-animation-duration: 1.5s;
    animation-timing-function: ease-in-out; 
    -webkit-animation-timing-function: ease-in-out;     
    visibility: visible !important; 
}

@keyframes fadeIn {
    0% {transform: scale(0);opacity: 0.0;}
    60% {transform: scale(1.1);}
    80% {transform: scale(0.9);opacity: 1;}   
    100% {transform: scale(1);opacity: 1;}       
}

@-webkit-keyframes fadeIn {
    0% {-webkit-transform: scale(0);opacity: 0.0;}
    60% {-webkit-transform: scale(1.1);}
    80% {-webkit-transform: scale(0.9);opacity: 1;}   
    100% {-webkit-transform: scale(1);opacity: 1;}       
}

/*expandOpen*/
.expandOpen{
    animation-name: expandOpen;
    -webkit-animation-name: expandOpen; 
    animation-duration: 1.2s;   
    -webkit-animation-duration: 1.2s;
    animation-timing-function: ease-out;    
    -webkit-animation-timing-function: ease-out;    
    visibility: visible !important; 
}

@keyframes expandOpen {
    0% {transform: scale(1.8);}
    50% {transform: scale(0.95);}   
    80% {transform: scale(1.05);}
    90% {transform: scale(0.98);}   
    100% {transform: scale(1);}
}

@-webkit-keyframes expandOpen {
    0% {-webkit-transform: scale(1.8);}
    50% {-webkit-transform: scale(0.95);}   
    80% {-webkit-transform: scale(1.05);}
    90% {-webkit-transform: scale(0.98);}   
    100% {-webkit-transform: scale(1);}        
}

/*bigEntrance*/
.bigEntrance{
    animation-name: bigEntrance;
    -webkit-animation-name: bigEntrance;    
    animation-duration: 1.6s;   
    -webkit-animation-duration: 1.6s;
    animation-timing-function: ease-out;    
    -webkit-animation-timing-function: ease-out;    
    visibility: visible !important;         
}

@keyframes bigEntrance {
    0% {transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);opacity: 0.2;}
    30% {transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);opacity: 1;}
    45% {transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);opacity: 1;}
    60% {transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);opacity: 1;}   
    75% {transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);opacity: 1;}
    90% {transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%); opacity: 1;}   
    100% {transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);opacity: 1;}       
}

@-webkit-keyframes bigEntrance {
    0% {-webkit-transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);opacity: 0.2;}
    30% {-webkit-transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);opacity: 1;}
    45% {-webkit-transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);opacity: 1;}
    60% {-webkit-transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);opacity: 1;}   
    75% {-webkit-transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);opacity: 1;}
    90% {-webkit-transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%); opacity: 1;}   
    100% {-webkit-transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);opacity: 1;}    
}

/*hatch*/

.hatch{
    animation-name: hatch;
    -webkit-animation-name: hatch;  
    animation-duration: 2s; 
    -webkit-animation-duration: 2s;
    animation-timing-function: ease-in-out; 
    -webkit-animation-timing-function: ease-in-out;
    transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -webkit-transform-origin: 50% 100%; 
    visibility: visible !important;     
}

@keyframes hatch {
    0% {transform: rotate(0deg) scaleY(0.6);}
    20% {transform: rotate(-2deg) scaleY(1.05);}
    35% {transform: rotate(2deg) scaleY(1);}
    50% {transform: rotate(-2deg);}   
    65% {transform: rotate(1deg);}   
    80% {transform: rotate(-1deg);}       
    100% {transform: rotate(0deg);}
}

@-webkit-keyframes hatch {
    0% {-webkit-transform: rotate(0deg) scaleY(0.6);}
    20% {-webkit-transform: rotate(-2deg) scaleY(1.05);}
    35% {-webkit-transform: rotate(2deg) scaleY(1);}
    50% {-webkit-transform: rotate(-2deg);}   
    65% {-webkit-transform: rotate(1deg);}   
    80% {-webkit-transform: rotate(-1deg);}       
    100% {-webkit-transform: rotate(0deg);}
}


/*bounce*/
.bounce{
    animation-name: bounce;
    -webkit-animation-name: bounce; 
    animation-duration: 1.6s;   
    -webkit-animation-duration: 1.6s;
    animation-timing-function: ease;    
    -webkit-animation-timing-function: ease;    
    transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -webkit-transform-origin: 50% 100%;     
}

@keyframes bounce {
    0% {transform: translateY(0%) scaleY(0.6);}
    60%{transform: translateY(-100%) scaleY(1.1);}
    70%{transform: translateY(0%) scaleY(0.95) scaleX(1.05);}
    80%{transform: translateY(0%) scaleY(1.05) scaleX(1);}   
    90%{transform: translateY(0%) scaleY(0.95) scaleX(1);}
    100%{transform: translateY(0%) scaleY(1) scaleX(1);}   
}

@-webkit-keyframes bounce {
    0% {-webkit-transform: translateY(0%) scaleY(0.6);}
    60%{-webkit-transform: translateY(-100%) scaleY(1.1);}
    70%{-webkit-transform: translateY(0%) scaleY(0.95) scaleX(1.05);}
    80%{-webkit-transform: translateY(0%) scaleY(1.05) scaleX(1);}   
    90%{-webkit-transform: translateY(0%) scaleY(0.95) scaleX(1);}
    100%{-webkit-transform: translateY(0%) scaleY(1) scaleX(1);}       
}


/*pulse*/
.pulse{
    animation-name: pulse;
    -webkit-animation-name: pulse;  
    animation-duration: 1.5s;   
    -webkit-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

@keyframes pulse {
    0% {transform: scale(0.9);opacity: 0.7;}
    50% {transform: scale(1);opacity: 1;}   
    100% {transform: scale(0.9);opacity: 0.7;}
}

@-webkit-keyframes pulse {
    0% {-webkit-transform: scale(0.95);opacity: 0.7;}
    50% {-webkit-transform: scale(1);opacity: 1;}   
    100% {-webkit-transform: scale(0.95);opacity: 0.7;}
}

/*floating*/
.floating{
    animation-name: floating;
    -webkit-animation-name: floating;
    animation-duration: 1.5s;   
    -webkit-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

@keyframes floating {
    0% {transform: translateY(0%);}
    50% {transform: translateY(8%);}   
    100% {transform: translateY(0%);}
}

@-webkit-keyframes floating {
    0% {-webkit-transform: translateY(0%);}
    50% {-webkit-transform: translateY(8%);}   
    100% {-webkit-transform: translateY(0%);}
}

/*tossing*/

.tossing{
    animation-name: tossing;
    -webkit-animation-name: tossing;    

    animation-duration: 2.5s;   
    -webkit-animation-duration: 2.5s;

    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

@keyframes tossing {
    0% {transform: rotate(-4deg);}
    50% {transform: rotate(4deg);}
    100% {transform: rotate(-4deg);}
}

@-webkit-keyframes tossing {
    0% {-webkit-transform: rotate(-4deg);}
    50% {-webkit-transform: rotate(4deg);}
    100% {-webkit-transform: rotate(-4deg);}
}

/*pullUp*/

.pullUp{
    animation-name: pullUp;
    -webkit-animation-name: pullUp; 
    animation-duration: 1.1s;   
    -webkit-animation-duration: 1.1s;
    animation-timing-function: ease-out;    
    -webkit-animation-timing-function: ease-out;    
    transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -webkit-transform-origin: 50% 100%;         
}

@keyframes pullUp {
    0% {transform: scaleY(0.1);}
    40% {transform: scaleY(1.02);}
    60% {transform: scaleY(0.98);}
    80% {transform: scaleY(1.01);}
    100% {transform: scaleY(0.98);}
    80% {transform: scaleY(1.01);}
    100% {transform: scaleY(1);}
}

@-webkit-keyframes pullUp {
    0% {-webkit-transform: scaleY(0.1);}
    40% {-webkit-transform: scaleY(1.02);}
    60% {-webkit-transform: scaleY(0.98);}
    80% {-webkit-transform: scaleY(1.01);}
    100% {-webkit-transform: scaleY(0.98);}
    80% {-webkit-transform: scaleY(1.01);}
    100% {-webkit-transform: scaleY(1);}
}

/*pullDown*/
.pullDown{
    animation-name: pullDown;
    -webkit-animation-name: pullDown;   
    animation-duration: 1.1s;   
    -webkit-animation-duration: 1.1s;
    animation-timing-function: ease-out;    
    -webkit-animation-timing-function: ease-out;    
    transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    -webkit-transform-origin: 50% 0%;       
}

@keyframes pullDown {
    0% {transform: scaleY(0.1);}
    40% {transform: scaleY(1.02);}
    60% {transform: scaleY(0.98);}
    80% {transform: scaleY(1.01);}
    100% {transform: scaleY(0.98);}
    80% {transform: scaleY(1.01);}
    100% {transform: scaleY(1);}
}

@-webkit-keyframes pullDown {
    0% {-webkit-transform: scaleY(0.1);}
    40% {-webkit-transform: scaleY(1.02);}
    60% {-webkit-transform: scaleY(0.98);}
    80% {-webkit-transform: scaleY(1.01);}
    100% {-webkit-transform: scaleY(0.98);}
    80% {-webkit-transform: scaleY(1.01);}
    100% {-webkit-transform: scaleY(1);}
}

/*stretchLeft*/

.stretchLeft{
    animation-name: stretchLeft;
    -webkit-animation-name: stretchLeft;    
    animation-duration: 1.5s;   
    -webkit-animation-duration: 1.5s;
    animation-timing-function: ease-out;    
    -webkit-animation-timing-function: ease-out;    
    transform-origin: 100% 0%;
    -ms-transform-origin: 100% 0%;
    -webkit-transform-origin: 100% 0%; 
}

@keyframes stretchLeft {
    0% {transform: scaleX(0.3);}
    40% {transform: scaleX(1.02);}
    60% {transform: scaleX(0.98);}
    80% {transform: scaleX(1.01);}
    100% {transform: scaleX(0.98);}
    80% {transform: scaleX(1.01);}
    100% {transform: scaleX(1);}
}

@-webkit-keyframes stretchLeft {
    0% {-webkit-transform: scaleX(0.3);}
    40% {-webkit-transform: scaleX(1.02);}
    60% {-webkit-transform: scaleX(0.98);}
    80% {-webkit-transform: scaleX(1.01);}
    100% {-webkit-transform: scaleX(0.98);}
    80% {-webkit-transform: scaleX(1.01);}
    100% {-webkit-transform: scaleX(1);}
}

/*stretchRight*/
.stretchRight{
    animation-name: stretchRight;
    -webkit-animation-name: stretchRight;   
    animation-duration: 1.5s;   
    -webkit-animation-duration: 1.5s;
    animation-timing-function: ease-out;    
    -webkit-animation-timing-function: ease-out;    
    transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -webkit-transform-origin: 0% 0%;        
}

@keyframes stretchRight {
    0% {transform: scaleX(0.3);}
    40% {transform: scaleX(1.02);}
    60% {transform: scaleX(0.98);}
    80% {transform: scaleX(1.01);}
    100% {transform: scaleX(0.98);}
    80% {transform: scaleX(1.01);}
    100% {transform: scaleX(1);}
}

@-webkit-keyframes stretchRight {
    0% {-webkit-transform: scaleX(0.3);}
    40% {-webkit-transform: scaleX(1.02);}
    60% {-webkit-transform: scaleX(0.98);}
    80% {-webkit-transform: scaleX(1.01);}
    100% {-webkit-transform: scaleX(0.98);}
    80% {-webkit-transform: scaleX(1.01);}
    100% {-webkit-transform: scaleX(1);}
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.flipOutX,
.animated.flipOutY,
.animated.bounceIn,
.animated.bounceOut {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
}

@-webkit-keyframes bounce {
  from, 20%, 53%, 80%, to {-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
  40%, 43% {-webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);-webkit-transform: translate3d(0, -30px, 0);transform: translate3d(0, -30px, 0);}
  70% {-webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);-webkit-transform: translate3d(0, -15px, 0);transform: translate3d(0, -15px, 0);}
  90% {-webkit-transform: translate3d(0,-4px,0);transform: translate3d(0,-4px,0);}
}

@keyframes bounce {
  from, 20%, 53%, 80%, to {-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
  40%, 43% {-webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);-webkit-transform: translate3d(0, -30px, 0);transform: translate3d(0, -30px, 0);}
  70% {-webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);-webkit-transform: translate3d(0, -15px, 0);transform: translate3d(0, -15px, 0);}
  90% {-webkit-transform: translate3d(0,-4px,0);transform: translate3d(0,-4px,0);}
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

@-webkit-keyframes flash {
  from, 50%, to {opacity: 1;}
  25%, 75% {opacity: 0;}
}

@keyframes flash {
  from, 50%, to {opacity: 1;}
  25%, 75% {opacity: 0;}
}

.flash {
  -webkit-animation-name: flash;
  animation-name: flash;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes pulse {
  from {-webkit-transform: scale3d(1, 1, 1);transform: scale3d(1, 1, 1);}
  50% {-webkit-transform: scale3d(1.05, 1.05, 1.05);transform: scale3d(1.05, 1.05, 1.05);}to {-webkit-transform: scale3d(1, 1, 1);transform: scale3d(1, 1, 1);}
}

@keyframes pulse {
  from {-webkit-transform: scale3d(1, 1, 1);transform: scale3d(1, 1, 1);}
  50% {-webkit-transform: scale3d(1.05, 1.05, 1.05);transform: scale3d(1.05, 1.05, 1.05);}to {-webkit-transform: scale3d(1, 1, 1);transform: scale3d(1, 1, 1);}
}

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes rubberBand {
  from {-webkit-transform: scale3d(1, 1, 1);transform: scale3d(1, 1, 1);}
  30% {-webkit-transform: scale3d(1.25, 0.75, 1);transform: scale3d(1.25, 0.75, 1);}
  40% {-webkit-transform: scale3d(0.75, 1.25, 1);transform: scale3d(0.75, 1.25, 1);}
  50% {-webkit-transform: scale3d(1.15, 0.85, 1);transform: scale3d(1.15, 0.85, 1);}
  65% {-webkit-transform: scale3d(.95, 1.05, 1);transform: scale3d(.95, 1.05, 1);}
  75% {-webkit-transform: scale3d(1.05, .95, 1);transform: scale3d(1.05, .95, 1);}to {-webkit-transform: scale3d(1, 1, 1);transform: scale3d(1, 1, 1);}
}

@keyframes rubberBand {
  from {-webkit-transform: scale3d(1, 1, 1);transform: scale3d(1, 1, 1);}
  30% {-webkit-transform: scale3d(1.25, 0.75, 1);transform: scale3d(1.25, 0.75, 1);}
  40% {-webkit-transform: scale3d(0.75, 1.25, 1);transform: scale3d(0.75, 1.25, 1);}
  50% {-webkit-transform: scale3d(1.15, 0.85, 1);transform: scale3d(1.15, 0.85, 1);}
  65% {-webkit-transform: scale3d(.95, 1.05, 1);transform: scale3d(.95, 1.05, 1);}
  75% {-webkit-transform: scale3d(1.05, .95, 1);transform: scale3d(1.05, .95, 1);}to {-webkit-transform: scale3d(1, 1, 1);transform: scale3d(1, 1, 1);}
}

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes shake {
  from, to {-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}
  10%, 30%, 50%, 70%, 90% {-webkit-transform: translate3d(-10px, 0, 0);transform: translate3d(-10px, 0, 0);}
  20%, 40%, 60%, 80% {-webkit-transform: translate3d(10px, 0, 0);transform: translate3d(10px, 0, 0);}
}

@keyframes shake {
  from, to {-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}
  10%, 30%, 50%, 70%, 90% {-webkit-transform: translate3d(-10px, 0, 0);transform: translate3d(-10px, 0, 0);}
  20%, 40%, 60%, 80% {-webkit-transform: translate3d(10px, 0, 0);transform: translate3d(10px, 0, 0);}
}

.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes headShake {
  0% {-webkit-transform: translateX(0);transform: translateX(0);}
  6.5% {-webkit-transform: translateX(-6px) rotateY(-9deg);transform: translateX(-6px) rotateY(-9deg);}
  18.5% {-webkit-transform: translateX(5px) rotateY(7deg);transform: translateX(5px) rotateY(7deg);}
  31.5% {-webkit-transform: translateX(-3px) rotateY(-5deg);transform: translateX(-3px) rotateY(-5deg);}
  43.5% {-webkit-transform: translateX(2px) rotateY(3deg);transform: translateX(2px) rotateY(3deg);}
  50% {-webkit-transform: translateX(0);transform: translateX(0);}
}

@keyframes headShake {
  0% {-webkit-transform: translateX(0);transform: translateX(0);}
  6.5% {-webkit-transform: translateX(-6px) rotateY(-9deg);transform: translateX(-6px) rotateY(-9deg);}
  18.5% {-webkit-transform: translateX(5px) rotateY(7deg);transform: translateX(5px) rotateY(7deg);}
  31.5% {-webkit-transform: translateX(-3px) rotateY(-5deg);transform: translateX(-3px) rotateY(-5deg);}
  43.5% {-webkit-transform: translateX(2px) rotateY(3deg);transform: translateX(2px) rotateY(3deg);}
  50% {-webkit-transform: translateX(0);transform: translateX(0);}
}

.headShake {
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: headShake;
  animation-name: headShake;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes swing {
  20% {-webkit-transform: rotate3d(0, 0, 1, 15deg);transform: rotate3d(0, 0, 1, 15deg);}
  40% {-webkit-transform: rotate3d(0, 0, 1, -10deg);transform: rotate3d(0, 0, 1, -10deg);}
  60% {-webkit-transform: rotate3d(0, 0, 1, 5deg);transform: rotate3d(0, 0, 1, 5deg);}
  80% {-webkit-transform: rotate3d(0, 0, 1, -5deg);transform: rotate3d(0, 0, 1, -5deg);}to {-webkit-transform: rotate3d(0, 0, 1, 0deg);transform: rotate3d(0, 0, 1, 0deg);}
}

@keyframes swing {
  20% {-webkit-transform: rotate3d(0, 0, 1, 15deg);transform: rotate3d(0, 0, 1, 15deg);}
  40% {-webkit-transform: rotate3d(0, 0, 1, -10deg);transform: rotate3d(0, 0, 1, -10deg);}
  60% {-webkit-transform: rotate3d(0, 0, 1, 5deg);transform: rotate3d(0, 0, 1, 5deg);}
  80% {-webkit-transform: rotate3d(0, 0, 1, -5deg);transform: rotate3d(0, 0, 1, -5deg);}to {-webkit-transform: rotate3d(0, 0, 1, 0deg);transform: rotate3d(0, 0, 1, 0deg);}
}

.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes tada {
  from {-webkit-transform: scale3d(1, 1, 1);transform: scale3d(1, 1, 1);}
  10%, 20% {-webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);}
  30%, 50%, 70%, 90% {-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);}
  40%, 60%, 80% {-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);}to {-webkit-transform: scale3d(1, 1, 1);transform: scale3d(1, 1, 1);}
}

@keyframes tada {
  from {-webkit-transform: scale3d(1, 1, 1);transform: scale3d(1, 1, 1);}
  10%, 20% {-webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);}
  30%, 50%, 70%, 90% {-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);}
  40%, 60%, 80% {-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);}to {-webkit-transform: scale3d(1, 1, 1);transform: scale3d(1, 1, 1);}
}

.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes wobble {
  from {-webkit-transform: none;transform: none;}
  15% {-webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);}
  30% {-webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);}
  45% {-webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);}
  60% {-webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);}
  75% {-webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);}to {-webkit-transform: none;transform: none;}
}

@keyframes wobble {
  from {-webkit-transform: none;transform: none;}
  15% {-webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);}
  30% {-webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);}
  45% {-webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);}
  60% {-webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);}
  75% {-webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);}to {-webkit-transform: none;transform: none;}
}

.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes jello {
  from, 11.1%, to {-webkit-transform: none;transform: none;}
  22.2% {-webkit-transform: skewX(-12.5deg) skewY(-12.5deg);transform: skewX(-12.5deg) skewY(-12.5deg);}
  33.3% {-webkit-transform: skewX(6.25deg) skewY(6.25deg);transform: skewX(6.25deg) skewY(6.25deg);}
  44.4% {-webkit-transform: skewX(-3.125deg) skewY(-3.125deg);transform: skewX(-3.125deg) skewY(-3.125deg);}
  55.5% {-webkit-transform: skewX(1.5625deg) skewY(1.5625deg);transform: skewX(1.5625deg) skewY(1.5625deg);}
  66.6% {-webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);transform: skewX(-0.78125deg) skewY(-0.78125deg);}
  77.7% {-webkit-transform: skewX(0.390625deg) skewY(0.390625deg);transform: skewX(0.390625deg) skewY(0.390625deg);}
  88.8% {-webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);transform: skewX(-0.1953125deg) skewY(-0.1953125deg);}
}

@keyframes jello {
  from, 11.1%, to {-webkit-transform: none;transform: none;}
  22.2% {-webkit-transform: skewX(-12.5deg) skewY(-12.5deg);transform: skewX(-12.5deg) skewY(-12.5deg);}
  33.3% {-webkit-transform: skewX(6.25deg) skewY(6.25deg);transform: skewX(6.25deg) skewY(6.25deg);}
  44.4% {-webkit-transform: skewX(-3.125deg) skewY(-3.125deg);transform: skewX(-3.125deg) skewY(-3.125deg);}
  55.5% {-webkit-transform: skewX(1.5625deg) skewY(1.5625deg);transform: skewX(1.5625deg) skewY(1.5625deg);}
  66.6% {-webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);transform: skewX(-0.78125deg) skewY(-0.78125deg);}
  77.7% {-webkit-transform: skewX(0.390625deg) skewY(0.390625deg);transform: skewX(0.390625deg) skewY(0.390625deg);}
  88.8% {-webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);transform: skewX(-0.1953125deg) skewY(-0.1953125deg);}
}

.jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
  -webkit-transform-origin: center;
  transform-origin: center;
}

@-webkit-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);}
  0% {opacity: 0;-webkit-transform: scale3d(.3, .3, .3);transform: scale3d(.3, .3, .3);}
  20% {-webkit-transform: scale3d(1.1, 1.1, 1.1);transform: scale3d(1.1, 1.1, 1.1);}
  40% {-webkit-transform: scale3d(.9, .9, .9);transform: scale3d(.9, .9, .9);}
  60% {opacity: 1;-webkit-transform: scale3d(1.03, 1.03, 1.03);transform: scale3d(1.03, 1.03, 1.03);}
  80% {-webkit-transform: scale3d(.97, .97, .97);transform: scale3d(.97, .97, .97);}to {opacity: 1;-webkit-transform: scale3d(1, 1, 1);transform: scale3d(1, 1, 1);}
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);}
  0% {opacity: 0;-webkit-transform: scale3d(.3, .3, .3);transform: scale3d(.3, .3, .3);}
  20% {-webkit-transform: scale3d(1.1, 1.1, 1.1);transform: scale3d(1.1, 1.1, 1.1);}
  40% {-webkit-transform: scale3d(.9, .9, .9);transform: scale3d(.9, .9, .9);}
  60% {opacity: 1;-webkit-transform: scale3d(1.03, 1.03, 1.03);transform: scale3d(1.03, 1.03, 1.03);}
  80% {-webkit-transform: scale3d(.97, .97, .97);transform: scale3d(.97, .97, .97);}to {opacity: 1;-webkit-transform: scale3d(1, 1, 1);transform: scale3d(1, 1, 1);}
}

.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes bounceInDown {
  from, 60%, 75%, 90%, to {-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);}
  0% {opacity: 0;-webkit-transform: translate3d(0, -3000px, 0);transform: translate3d(0, -3000px, 0);}
  60% {opacity: 1;-webkit-transform: translate3d(0, 25px, 0);transform: translate3d(0, 25px, 0);}
  75% {-webkit-transform: translate3d(0, -10px, 0);transform: translate3d(0, -10px, 0);}
  90% {-webkit-transform: translate3d(0, 5px, 0);transform: translate3d(0, 5px, 0);}to {-webkit-transform: none;transform: none;}
}

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);}
  0% {opacity: 0;-webkit-transform: translate3d(0, -3000px, 0);transform: translate3d(0, -3000px, 0);}
  60% {opacity: 1;-webkit-transform: translate3d(0, 25px, 0);transform: translate3d(0, 25px, 0);}
  75% {-webkit-transform: translate3d(0, -10px, 0);transform: translate3d(0, -10px, 0);}
  90% {-webkit-transform: translate3d(0, 5px, 0);transform: translate3d(0, 5px, 0);}to {-webkit-transform: none;transform: none;}
}

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);}
  0% {opacity: 0;-webkit-transform: translate3d(-3000px, 0, 0);transform: translate3d(-3000px, 0, 0);}
  60% {opacity: 1;-webkit-transform: translate3d(25px, 0, 0);transform: translate3d(25px, 0, 0);}
  75% {-webkit-transform: translate3d(-10px, 0, 0);transform: translate3d(-10px, 0, 0);}
  90% {-webkit-transform: translate3d(5px, 0, 0);transform: translate3d(5px, 0, 0);}to {-webkit-transform: none;transform: none;}
}

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);}
  0% {opacity: 0;-webkit-transform: translate3d(-3000px, 0, 0);transform: translate3d(-3000px, 0, 0);}
  60% {opacity: 1;-webkit-transform: translate3d(25px, 0, 0);transform: translate3d(25px, 0, 0);}
  75% {-webkit-transform: translate3d(-10px, 0, 0);transform: translate3d(-10px, 0, 0);}
  90% {-webkit-transform: translate3d(5px, 0, 0);transform: translate3d(5px, 0, 0);}to {-webkit-transform: none;transform: none;}
}

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes bounceInRight {
  from, 60%, 75%, 90%, to {-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);}
  from {opacity: 0;-webkit-transform: translate3d(3000px, 0, 0);transform: translate3d(3000px, 0, 0);}
  60% {opacity: 1;-webkit-transform: translate3d(-25px, 0, 0);transform: translate3d(-25px, 0, 0);}
  75% {-webkit-transform: translate3d(10px, 0, 0);transform: translate3d(10px, 0, 0);}
  90% {-webkit-transform: translate3d(-5px, 0, 0);transform: translate3d(-5px, 0, 0);}to {-webkit-transform: none;transform: none;}
}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);}
  from {opacity: 0;-webkit-transform: translate3d(3000px, 0, 0);transform: translate3d(3000px, 0, 0);}
  60% {opacity: 1;-webkit-transform: translate3d(-25px, 0, 0);transform: translate3d(-25px, 0, 0);}
  75% {-webkit-transform: translate3d(10px, 0, 0);transform: translate3d(10px, 0, 0);}
  90% {-webkit-transform: translate3d(-5px, 0, 0);transform: translate3d(-5px, 0, 0);}to {-webkit-transform: none;transform: none;}
}

.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes bounceInUp {
  from, 60%, 75%, 90%, to {-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);}
  from {opacity: 0;-webkit-transform: translate3d(0, 3000px, 0);transform: translate3d(0, 3000px, 0);}
  60% {opacity: 1;-webkit-transform: translate3d(0, -20px, 0);transform: translate3d(0, -20px, 0);}
  75% {-webkit-transform: translate3d(0, 10px, 0);transform: translate3d(0, 10px, 0);}
  90% {-webkit-transform: translate3d(0, -5px, 0);transform: translate3d(0, -5px, 0);}to {-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}
}

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {-webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);}
  from {opacity: 0;-webkit-transform: translate3d(0, 3000px, 0);transform: translate3d(0, 3000px, 0);}
  60% {opacity: 1;-webkit-transform: translate3d(0, -20px, 0);transform: translate3d(0, -20px, 0);}
  75% {-webkit-transform: translate3d(0, 10px, 0);transform: translate3d(0, 10px, 0);}
  90% {-webkit-transform: translate3d(0, -5px, 0);transform: translate3d(0, -5px, 0);}to {-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}
}

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes bounceOut {
  20% {-webkit-transform: scale3d(.9, .9, .9);transform: scale3d(.9, .9, .9);}
  50%, 55% {opacity: 1;-webkit-transform: scale3d(1.1, 1.1, 1.1);transform: scale3d(1.1, 1.1, 1.1);}to {opacity: 0;-webkit-transform: scale3d(.3, .3, .3);transform: scale3d(.3, .3, .3);}
}

@keyframes bounceOut {
  20% {-webkit-transform: scale3d(.9, .9, .9);transform: scale3d(.9, .9, .9);}
  50%, 55% {opacity: 1;-webkit-transform: scale3d(1.1, 1.1, 1.1);transform: scale3d(1.1, 1.1, 1.1);}to {opacity: 0;-webkit-transform: scale3d(.3, .3, .3);transform: scale3d(.3, .3, .3);}
}

.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes bounceOutDown {
  20% {-webkit-transform: translate3d(0, 10px, 0);transform: translate3d(0, 10px, 0);}
  40%, 45% {opacity: 1;-webkit-transform: translate3d(0, -20px, 0);transform: translate3d(0, -20px, 0);}to {opacity: 0;-webkit-transform: translate3d(0, 2000px, 0);transform: translate3d(0, 2000px, 0);}
}

@keyframes bounceOutDown {
  20% {-webkit-transform: translate3d(0, 10px, 0);transform: translate3d(0, 10px, 0);}
  40%, 45% {opacity: 1;-webkit-transform: translate3d(0, -20px, 0);transform: translate3d(0, -20px, 0);}to {opacity: 0;-webkit-transform: translate3d(0, 2000px, 0);transform: translate3d(0, 2000px, 0);}
}

.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes bounceOutLeft {
  20% {opacity: 1;-webkit-transform: translate3d(20px, 0, 0);transform: translate3d(20px, 0, 0);}to {opacity: 0;-webkit-transform: translate3d(-2000px, 0, 0);transform: translate3d(-2000px, 0, 0);}
}

@keyframes bounceOutLeft {
  20% {opacity: 1;-webkit-transform: translate3d(20px, 0, 0);transform: translate3d(20px, 0, 0);}to {opacity: 0;-webkit-transform: translate3d(-2000px, 0, 0);transform: translate3d(-2000px, 0, 0);}
}

.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes bounceOutRight {
  20% {opacity: 1;-webkit-transform: translate3d(-20px, 0, 0);transform: translate3d(-20px, 0, 0);}to {opacity: 0;-webkit-transform: translate3d(2000px, 0, 0);transform: translate3d(2000px, 0, 0);}
}

@keyframes bounceOutRight {
  20% {opacity: 1;-webkit-transform: translate3d(-20px, 0, 0);transform: translate3d(-20px, 0, 0);}to {opacity: 0;-webkit-transform: translate3d(2000px, 0, 0);transform: translate3d(2000px, 0, 0);}
}

.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes bounceOutUp {
  20% {-webkit-transform: translate3d(0, -10px, 0);transform: translate3d(0, -10px, 0);}
  40%, 45% {opacity: 1;-webkit-transform: translate3d(0, 20px, 0);transform: translate3d(0, 20px, 0);}to {opacity: 0;-webkit-transform: translate3d(0, -2000px, 0);transform: translate3d(0, -2000px, 0);}
}

@keyframes bounceOutUp {
  20% {-webkit-transform: translate3d(0, -10px, 0);transform: translate3d(0, -10px, 0);}
  40%, 45% {opacity: 1;-webkit-transform: translate3d(0, 20px, 0);transform: translate3d(0, 20px, 0);}to {opacity: 0;-webkit-transform: translate3d(0, -2000px, 0);transform: translate3d(0, -2000px, 0);}
}

.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes fadeIn {
  from {opacity: 0;}to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}to {opacity: 1;}
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes fadeInDown {
  from {opacity: 0;-webkit-transform: translate3d(0, -100%, 0);transform: translate3d(0, -100%, 0);}to {opacity: 1;-webkit-transform: none;transform: none;}
}

@keyframes fadeInDown {
  from {opacity: 0;-webkit-transform: translate3d(0, -100%, 0);transform: translate3d(0, -100%, 0);}to {opacity: 1;-webkit-transform: none;transform: none;}
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes fadeInDownBig {
  from {opacity: 0;-webkit-transform: translate3d(0, -2000px, 0);transform: translate3d(0, -2000px, 0);}to {opacity: 1;-webkit-transform: none;transform: none;}
}

@keyframes fadeInDownBig {
  from {opacity: 0;-webkit-transform: translate3d(0, -2000px, 0);transform: translate3d(0, -2000px, 0);}to {opacity: 1;-webkit-transform: none;transform: none;}
}

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes fadeInLeft {
  from {opacity: 0;-webkit-transform: translate3d(-100%, 0, 0);transform: translate3d(-100%, 0, 0);}to {opacity: 1;-webkit-transform: none;transform: none;}
}

@keyframes fadeInLeft {
  from {opacity: 0;-webkit-transform: translate3d(-100%, 0, 0);transform: translate3d(-100%, 0, 0);}to {opacity: 1;-webkit-transform: none;transform: none;}
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes fadeInLeftBig {
  from {opacity: 0;-webkit-transform: translate3d(-2000px, 0, 0);transform: translate3d(-2000px, 0, 0);}to {opacity: 1;-webkit-transform: none;transform: none;}
}

@keyframes fadeInLeftBig {
  from {opacity: 0;-webkit-transform: translate3d(-2000px, 0, 0);transform: translate3d(-2000px, 0, 0);}to {opacity: 1;-webkit-transform: none;transform: none;}
}

.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes fadeInRight {
  from {opacity: 0;-webkit-transform: translate3d(100%, 0, 0);transform: translate3d(100%, 0, 0);}to {opacity: 1;-webkit-transform: none;transform: none;}
}

@keyframes fadeInRight {
  from {opacity: 0;-webkit-transform: translate3d(100%, 0, 0);transform: translate3d(100%, 0, 0);}to {opacity: 1;-webkit-transform: none;transform: none;}
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes fadeInRightBig {
  from {opacity: 0;-webkit-transform: translate3d(2000px, 0, 0);transform: translate3d(2000px, 0, 0);}to {opacity: 1;-webkit-transform: none;transform: none;}
}

@keyframes fadeInRightBig {
  from {opacity: 0;-webkit-transform: translate3d(2000px, 0, 0);transform: translate3d(2000px, 0, 0);}to {opacity: 1;-webkit-transform: none;transform: none;}
}

.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes fadeInUp {
  from {opacity: 0;-webkit-transform: translate3d(0, 100%, 0);transform: translate3d(0, 100%, 0);}to {opacity: 1;-webkit-transform: none;transform: none;}
}

@keyframes fadeInUp {
  from {opacity: 0;-webkit-transform: translate3d(0, 100%, 0);transform: translate3d(0, 100%, 0);}to {opacity: 1;-webkit-transform: none;transform: none;}
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes fadeInUpBig {
  from {opacity: 0;-webkit-transform: translate3d(0, 2000px, 0);transform: translate3d(0, 2000px, 0);}to {opacity: 1;-webkit-transform: none;transform: none;}
}

@keyframes fadeInUpBig {
  from {opacity: 0;-webkit-transform: translate3d(0, 2000px, 0);transform: translate3d(0, 2000px, 0);}to {opacity: 1;-webkit-transform: none;transform: none;}
}

.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes fadeOut {
  from {opacity: 1;}to {opacity: 0;}
}

@keyframes fadeOut {
  from {opacity: 1;}to {opacity: 0;}
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes fadeOutDown {
  from {opacity: 1;}to {opacity: 0;-webkit-transform: translate3d(0, 100%, 0);transform: translate3d(0, 100%, 0);}
}

@keyframes fadeOutDown {
  from {opacity: 1;}to {opacity: 0;-webkit-transform: translate3d(0, 100%, 0);transform: translate3d(0, 100%, 0);}
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes fadeOutDownBig {
  from {opacity: 1;}to {opacity: 0;-webkit-transform: translate3d(0, 2000px, 0);transform: translate3d(0, 2000px, 0);}
}

@keyframes fadeOutDownBig {
  from {opacity: 1;}to {opacity: 0;-webkit-transform: translate3d(0, 2000px, 0);transform: translate3d(0, 2000px, 0);}
}

.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes fadeOutLeft {
  from {opacity: 1;}to {opacity: 0;-webkit-transform: translate3d(-100%, 0, 0);transform: translate3d(-100%, 0, 0);}
}

@keyframes fadeOutLeft {
  from {opacity: 1;}to {opacity: 0;-webkit-transform: translate3d(-100%, 0, 0);transform: translate3d(-100%, 0, 0);}
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes fadeOutLeftBig {
  from {opacity: 1;}to {opacity: 0;-webkit-transform: translate3d(-2000px, 0, 0);transform: translate3d(-2000px, 0, 0);}
}

@keyframes fadeOutLeftBig {
  from {opacity: 1;}to {opacity: 0;-webkit-transform: translate3d(-2000px, 0, 0);transform: translate3d(-2000px, 0, 0);}
}

.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes fadeOutRight {
  from {opacity: 1;}to {opacity: 0;-webkit-transform: translate3d(100%, 0, 0);transform: translate3d(100%, 0, 0);}
}

@keyframes fadeOutRight {
  from {opacity: 1;}to {opacity: 0;-webkit-transform: translate3d(100%, 0, 0);transform: translate3d(100%, 0, 0);}
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes fadeOutRightBig {
  from {opacity: 1;}to {opacity: 0;-webkit-transform: translate3d(2000px, 0, 0);transform: translate3d(2000px, 0, 0);}
}

@keyframes fadeOutRightBig {
  from {opacity: 1;}to {opacity: 0;-webkit-transform: translate3d(2000px, 0, 0);transform: translate3d(2000px, 0, 0);}
}

.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes fadeOutUp {
  from {opacity: 1;}to {opacity: 0;-webkit-transform: translate3d(0, -100%, 0);transform: translate3d(0, -100%, 0);}
}

@keyframes fadeOutUp {
  from {opacity: 1;}to {opacity: 0;-webkit-transform: translate3d(0, -100%, 0);transform: translate3d(0, -100%, 0);}
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes fadeOutUpBig {
  from {opacity: 1;}to {opacity: 0;-webkit-transform: translate3d(0, -2000px, 0);transform: translate3d(0, -2000px, 0);}
}

@keyframes fadeOutUpBig {
  from {opacity: 1;}to {opacity: 0;-webkit-transform: translate3d(0, -2000px, 0);transform: translate3d(0, -2000px, 0);}
}

.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes flip {
  from {-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);transform: perspective(400px) rotate3d(0, 1, 0, -360deg);-webkit-animation-timing-function: ease-out;animation-timing-function: ease-out;}
  40% {-webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);-webkit-animation-timing-function: ease-out;animation-timing-function: ease-out;}
  50% {-webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);-webkit-animation-timing-function: ease-in;animation-timing-function: ease-in;}
  80% {-webkit-transform: perspective(400px) scale3d(.95, .95, .95);transform: perspective(400px) scale3d(.95, .95, .95);-webkit-animation-timing-function: ease-in;animation-timing-function: ease-in;}to {-webkit-transform: perspective(400px);transform: perspective(400px);-webkit-animation-timing-function: ease-in;animation-timing-function: ease-in;}
}

@keyframes flip {
  from {-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);transform: perspective(400px) rotate3d(0, 1, 0, -360deg);-webkit-animation-timing-function: ease-out;animation-timing-function: ease-out;}
  40% {-webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);-webkit-animation-timing-function: ease-out;animation-timing-function: ease-out;}
  50% {-webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);-webkit-animation-timing-function: ease-in;animation-timing-function: ease-in;}
  80% {-webkit-transform: perspective(400px) scale3d(.95, .95, .95);transform: perspective(400px) scale3d(.95, .95, .95);-webkit-animation-timing-function: ease-in;animation-timing-function: ease-in;}to {-webkit-transform: perspective(400px);transform: perspective(400px);-webkit-animation-timing-function: ease-in;animation-timing-function: ease-in;}
}

.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes flipInX {
  from {-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);transform: perspective(400px) rotate3d(1, 0, 0, 90deg);-webkit-animation-timing-function: ease-in;animation-timing-function: ease-in;opacity: 0;}
  40% {-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);transform: perspective(400px) rotate3d(1, 0, 0, -20deg);-webkit-animation-timing-function: ease-in;animation-timing-function: ease-in;}
  60% {-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);transform: perspective(400px) rotate3d(1, 0, 0, 10deg);opacity: 1;}
  80% {-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);transform: perspective(400px) rotate3d(1, 0, 0, -5deg);}to {-webkit-transform: perspective(400px);transform: perspective(400px);}
}

@keyframes flipInX {
  from {-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);transform: perspective(400px) rotate3d(1, 0, 0, 90deg);-webkit-animation-timing-function: ease-in;animation-timing-function: ease-in;opacity: 0;}
  40% {-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);transform: perspective(400px) rotate3d(1, 0, 0, -20deg);-webkit-animation-timing-function: ease-in;animation-timing-function: ease-in;}
  60% {-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);transform: perspective(400px) rotate3d(1, 0, 0, 10deg);opacity: 1;}
  80% {-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);transform: perspective(400px) rotate3d(1, 0, 0, -5deg);}to {-webkit-transform: perspective(400px);transform: perspective(400px);}
}

.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes flipInY {
  from {-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);transform: perspective(400px) rotate3d(0, 1, 0, 90deg);-webkit-animation-timing-function: ease-in;animation-timing-function: ease-in;opacity: 0;}
  40% {-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);transform: perspective(400px) rotate3d(0, 1, 0, -20deg);-webkit-animation-timing-function: ease-in;animation-timing-function: ease-in;}
  60% {-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);transform: perspective(400px) rotate3d(0, 1, 0, 10deg);opacity: 1;}
  80% {-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);transform: perspective(400px) rotate3d(0, 1, 0, -5deg);}to {-webkit-transform: perspective(400px);transform: perspective(400px);}
}

@keyframes flipInY {
  from {-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);transform: perspective(400px) rotate3d(0, 1, 0, 90deg);-webkit-animation-timing-function: ease-in;animation-timing-function: ease-in;opacity: 0;}
  40% {-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);transform: perspective(400px) rotate3d(0, 1, 0, -20deg);-webkit-animation-timing-function: ease-in;animation-timing-function: ease-in;}
  60% {-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);transform: perspective(400px) rotate3d(0, 1, 0, 10deg);opacity: 1;}
  80% {-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);transform: perspective(400px) rotate3d(0, 1, 0, -5deg);}to {-webkit-transform: perspective(400px);transform: perspective(400px);}
}

.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes flipOutX {
  from {-webkit-transform: perspective(400px);transform: perspective(400px);}
  30% {-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);transform: perspective(400px) rotate3d(1, 0, 0, -20deg);opacity: 1;}to {-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);transform: perspective(400px) rotate3d(1, 0, 0, 90deg);opacity: 0;}
}

@keyframes flipOutX {
  from {-webkit-transform: perspective(400px);transform: perspective(400px);}
  30% {-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);transform: perspective(400px) rotate3d(1, 0, 0, -20deg);opacity: 1;}to {-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);transform: perspective(400px) rotate3d(1, 0, 0, 90deg);opacity: 0;}
}

.flipOutX {
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@-webkit-keyframes flipOutY {
  from {-webkit-transform: perspective(400px);transform: perspective(400px);}
  30% {-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);transform: perspective(400px) rotate3d(0, 1, 0, -15deg);opacity: 1;}to {-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);transform: perspective(400px) rotate3d(0, 1, 0, 90deg);opacity: 0;}
}

@keyframes flipOutY {
  from {-webkit-transform: perspective(400px);transform: perspective(400px);}
  30% {-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);transform: perspective(400px) rotate3d(0, 1, 0, -15deg);opacity: 1;}to {-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);transform: perspective(400px) rotate3d(0, 1, 0, 90deg);opacity: 0;}
}

.flipOutY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes lightSpeedIn {
  from {-webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);transform: translate3d(100%, 0, 0) skewX(-30deg);opacity: 0;}
  60% {-webkit-transform: skewX(20deg);transform: skewX(20deg);opacity: 1;}
  80% {-webkit-transform: skewX(-5deg);transform: skewX(-5deg);opacity: 1;}to {-webkit-transform: none;transform: none;opacity: 1;}
}

@keyframes lightSpeedIn {
  from {-webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);transform: translate3d(100%, 0, 0) skewX(-30deg);opacity: 0;}
  60% {-webkit-transform: skewX(20deg);transform: skewX(20deg);opacity: 1;}
  80% {-webkit-transform: skewX(-5deg);transform: skewX(-5deg);opacity: 1;}to {-webkit-transform: none;transform: none;opacity: 1;}
}

.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedOut {
  from {opacity: 1;}to {-webkit-transform: translate3d(100%, 0, 0) skewX(30deg);transform: translate3d(100%, 0, 0) skewX(30deg);opacity: 0;}
}

@keyframes lightSpeedOut {
  from {opacity: 1;}to {-webkit-transform: translate3d(100%, 0, 0) skewX(30deg);transform: translate3d(100%, 0, 0) skewX(30deg);opacity: 0;}
}

.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

@-webkit-keyframes rotateIn {
  from {-webkit-transform-origin: center;transform-origin: center;-webkit-transform: rotate3d(0, 0, 1, -200deg);transform: rotate3d(0, 0, 1, -200deg);opacity: 0;}to {-webkit-transform-origin: center;transform-origin: center;-webkit-transform: none;transform: none;opacity: 1;}
}

@keyframes rotateIn {
  from {-webkit-transform-origin: center;transform-origin: center;-webkit-transform: rotate3d(0, 0, 1, -200deg);transform: rotate3d(0, 0, 1, -200deg);opacity: 0;}to {-webkit-transform-origin: center;transform-origin: center;-webkit-transform: none;transform: none;opacity: 1;}
}

.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes rotateInDownLeft {
  from {-webkit-transform-origin: left bottom;transform-origin: left bottom;-webkit-transform: rotate3d(0, 0, 1, -45deg);transform: rotate3d(0, 0, 1, -45deg);opacity: 0;}to {-webkit-transform-origin: left bottom;transform-origin: left bottom;-webkit-transform: none;transform: none;opacity: 1;}
}

@keyframes rotateInDownLeft {
  from {-webkit-transform-origin: left bottom;transform-origin: left bottom;-webkit-transform: rotate3d(0, 0, 1, -45deg);transform: rotate3d(0, 0, 1, -45deg);opacity: 0;}to {-webkit-transform-origin: left bottom;transform-origin: left bottom;-webkit-transform: none;transform: none;opacity: 1;}
}

.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes rotateInDownRight {
  from {-webkit-transform-origin: right bottom;transform-origin: right bottom;-webkit-transform: rotate3d(0, 0, 1, 45deg);transform: rotate3d(0, 0, 1, 45deg);opacity: 0;}to {-webkit-transform-origin: right bottom;transform-origin: right bottom;-webkit-transform: none;transform: none;opacity: 1;}
}

@keyframes rotateInDownRight {
  from {-webkit-transform-origin: right bottom;transform-origin: right bottom;-webkit-transform: rotate3d(0, 0, 1, 45deg);transform: rotate3d(0, 0, 1, 45deg);opacity: 0;}to {-webkit-transform-origin: right bottom;transform-origin: right bottom;-webkit-transform: none;transform: none;opacity: 1;}
}

.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes rotateInUpLeft {
  from {-webkit-transform-origin: left bottom;transform-origin: left bottom;-webkit-transform: rotate3d(0, 0, 1, 45deg);transform: rotate3d(0, 0, 1, 45deg);opacity: 0;}to {-webkit-transform-origin: left bottom;transform-origin: left bottom;-webkit-transform: none;transform: none;opacity: 1;}
}

@keyframes rotateInUpLeft {
  from {-webkit-transform-origin: left bottom;transform-origin: left bottom;-webkit-transform: rotate3d(0, 0, 1, 45deg);transform: rotate3d(0, 0, 1, 45deg);opacity: 0;}to {-webkit-transform-origin: left bottom;transform-origin: left bottom;-webkit-transform: none;transform: none;opacity: 1;}
}

.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes rotateInUpRight {
  from {-webkit-transform-origin: right bottom;transform-origin: right bottom;-webkit-transform: rotate3d(0, 0, 1, -90deg);transform: rotate3d(0, 0, 1, -90deg);opacity: 0;}to {-webkit-transform-origin: right bottom;transform-origin: right bottom;-webkit-transform: none;transform: none;opacity: 1;}
}

@keyframes rotateInUpRight {
  from {-webkit-transform-origin: right bottom;transform-origin: right bottom;-webkit-transform: rotate3d(0, 0, 1, -90deg);transform: rotate3d(0, 0, 1, -90deg);opacity: 0;}to {-webkit-transform-origin: right bottom;transform-origin: right bottom;-webkit-transform: none;transform: none;opacity: 1;}
}

.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes rotateOut {
  from {-webkit-transform-origin: center;transform-origin: center;opacity: 1;}to {-webkit-transform-origin: center;transform-origin: center;-webkit-transform: rotate3d(0, 0, 1, 200deg);transform: rotate3d(0, 0, 1, 200deg);opacity: 0;}
}

@keyframes rotateOut {
  from {-webkit-transform-origin: center;transform-origin: center;opacity: 1;}to {-webkit-transform-origin: center;transform-origin: center;-webkit-transform: rotate3d(0, 0, 1, 200deg);transform: rotate3d(0, 0, 1, 200deg);opacity: 0;}
}

.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes rotateOutDownLeft {
  from {-webkit-transform-origin: left bottom;transform-origin: left bottom;opacity: 1;}to {-webkit-transform-origin: left bottom;transform-origin: left bottom;-webkit-transform: rotate3d(0, 0, 1, 45deg);transform: rotate3d(0, 0, 1, 45deg);opacity: 0;}
}

@keyframes rotateOutDownLeft {
  from {-webkit-transform-origin: left bottom;transform-origin: left bottom;opacity: 1;}to {-webkit-transform-origin: left bottom;transform-origin: left bottom;-webkit-transform: rotate3d(0, 0, 1, 45deg);transform: rotate3d(0, 0, 1, 45deg);opacity: 0;}
}

.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes rotateOutDownRight {
  from {-webkit-transform-origin: right bottom;transform-origin: right bottom;opacity: 1;}to {-webkit-transform-origin: right bottom;transform-origin: right bottom;-webkit-transform: rotate3d(0, 0, 1, -45deg);transform: rotate3d(0, 0, 1, -45deg);opacity: 0;}
}

@keyframes rotateOutDownRight {
  from {-webkit-transform-origin: right bottom;transform-origin: right bottom;opacity: 1;}to {-webkit-transform-origin: right bottom;transform-origin: right bottom;-webkit-transform: rotate3d(0, 0, 1, -45deg);transform: rotate3d(0, 0, 1, -45deg);opacity: 0;}
}

.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes rotateOutUpLeft {
  from {-webkit-transform-origin: left bottom;transform-origin: left bottom;opacity: 1;}to {-webkit-transform-origin: left bottom;transform-origin: left bottom;-webkit-transform: rotate3d(0, 0, 1, -45deg);transform: rotate3d(0, 0, 1, -45deg);opacity: 0;}
}

@keyframes rotateOutUpLeft {
  from {-webkit-transform-origin: left bottom;transform-origin: left bottom;opacity: 1;}to {-webkit-transform-origin: left bottom;transform-origin: left bottom;-webkit-transform: rotate3d(0, 0, 1, -45deg);transform: rotate3d(0, 0, 1, -45deg);opacity: 0;}
}

.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes rotateOutUpRight {
  from {-webkit-transform-origin: right bottom;transform-origin: right bottom;opacity: 1;}to {-webkit-transform-origin: right bottom;transform-origin: right bottom;-webkit-transform: rotate3d(0, 0, 1, 90deg);transform: rotate3d(0, 0, 1, 90deg);opacity: 0;}
}

@keyframes rotateOutUpRight {
  from {-webkit-transform-origin: right bottom;transform-origin: right bottom;opacity: 1;}to {-webkit-transform-origin: right bottom;transform-origin: right bottom;-webkit-transform: rotate3d(0, 0, 1, 90deg);transform: rotate3d(0, 0, 1, 90deg);opacity: 0;}
}

.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes hinge {
  0% {-webkit-transform-origin: top left;transform-origin: top left;-webkit-animation-timing-function: ease-in-out;animation-timing-function: ease-in-out;}
  20%, 60% {-webkit-transform: rotate3d(0, 0, 1, 80deg);transform: rotate3d(0, 0, 1, 80deg);-webkit-transform-origin: top left;transform-origin: top left;-webkit-animation-timing-function: ease-in-out;animation-timing-function: ease-in-out;}
  40%, 80% {-webkit-transform: rotate3d(0, 0, 1, 60deg);transform: rotate3d(0, 0, 1, 60deg);-webkit-transform-origin: top left;transform-origin: top left;-webkit-animation-timing-function: ease-in-out;animation-timing-function: ease-in-out;opacity: 1;}to {-webkit-transform: translate3d(0, 700px, 0);transform: translate3d(0, 700px, 0);opacity: 0;}
}

@keyframes hinge {
  0% {-webkit-transform-origin: top left;transform-origin: top left;-webkit-animation-timing-function: ease-in-out;animation-timing-function: ease-in-out;}
  20%, 60% {-webkit-transform: rotate3d(0, 0, 1, 80deg);transform: rotate3d(0, 0, 1, 80deg);-webkit-transform-origin: top left;transform-origin: top left;-webkit-animation-timing-function: ease-in-out;animation-timing-function: ease-in-out;}
  40%, 80% {-webkit-transform: rotate3d(0, 0, 1, 60deg);transform: rotate3d(0, 0, 1, 60deg);-webkit-transform-origin: top left;transform-origin: top left;-webkit-animation-timing-function: ease-in-out;animation-timing-function: ease-in-out;opacity: 1;}to {-webkit-transform: translate3d(0, 700px, 0);transform: translate3d(0, 700px, 0);opacity: 0;}
}

.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes rollIn {
  from {opacity: 0;-webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);}to {opacity: 1;-webkit-transform: none;transform: none;}
}

@keyframes rollIn {
  from {opacity: 0;-webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);}to {opacity: 1;-webkit-transform: none;transform: none;}
}

.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes rollOut {
  from {opacity: 1;}to {opacity: 0;-webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);}
}

@keyframes rollOut {
  from {opacity: 1;}to {opacity: 0;-webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);}
}

.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes zoomIn {
  from {opacity: 0;-webkit-transform: scale3d(.3, .3, .3);transform: scale3d(.3, .3, .3);}
  50% {opacity: 1;}
}

@keyframes zoomIn {
  from {opacity: 0;-webkit-transform: scale3d(.3, .3, .3);transform: scale3d(.3, .3, .3);}
  50% {opacity: 1;}
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes zoomInDown {
  from {opacity: 0;-webkit-transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);}
  60% {opacity: 1;-webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);}
}

@keyframes zoomInDown {
  from {opacity: 0;-webkit-transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);}
  60% {opacity: 1;-webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);}
}

.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes zoomInLeft {
  from {opacity: 0;-webkit-transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);}
  60% {opacity: 1;-webkit-transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);}
}

@keyframes zoomInLeft {
  from {opacity: 0;-webkit-transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);}
  60% {opacity: 1;-webkit-transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);}
}

.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes zoomInRight {
  from {opacity: 0;-webkit-transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);}
  60% {opacity: 1;-webkit-transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);}
}

@keyframes zoomInRight {
  from {opacity: 0;-webkit-transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);}
  60% {opacity: 1;-webkit-transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);}
}

.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes zoomInUp {
  from {opacity: 0;-webkit-transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);}
  60% {opacity: 1;-webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);}
}

@keyframes zoomInUp {
  from {opacity: 0;-webkit-transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);}
  60% {opacity: 1;-webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);}
}

.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes zoomOut {
  from {opacity: 1;}
  50% {opacity: 0;-webkit-transform: scale3d(.3, .3, .3);transform: scale3d(.3, .3, .3);}to {opacity: 0;}
}

@keyframes zoomOut {
  from {opacity: 1;}
  50% {opacity: 0;-webkit-transform: scale3d(.3, .3, .3);transform: scale3d(.3, .3, .3);}to {opacity: 0;}
}

.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes zoomOutDown {
  40% {opacity: 1;-webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);}to {opacity: 0;-webkit-transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);-webkit-transform-origin: center bottom;transform-origin: center bottom;-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);}
}

@keyframes zoomOutDown {
  40% {opacity: 1;-webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);}to {opacity: 0;-webkit-transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);-webkit-transform-origin: center bottom;transform-origin: center bottom;-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);}
}

.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes zoomOutLeft {
  40% {opacity: 1;-webkit-transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0);transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0);}to {opacity: 0;-webkit-transform: scale(.1) translate3d(-2000px, 0, 0);transform: scale(.1) translate3d(-2000px, 0, 0);-webkit-transform-origin: left center;transform-origin: left center;}
}

@keyframes zoomOutLeft {
  40% {opacity: 1;-webkit-transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0);transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0);}to {opacity: 0;-webkit-transform: scale(.1) translate3d(-2000px, 0, 0);transform: scale(.1) translate3d(-2000px, 0, 0);-webkit-transform-origin: left center;transform-origin: left center;}
}

.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes zoomOutRight {
  40% {opacity: 1;-webkit-transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0);transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0);}to {opacity: 0;-webkit-transform: scale(.1) translate3d(2000px, 0, 0);transform: scale(.1) translate3d(2000px, 0, 0);-webkit-transform-origin: right center;transform-origin: right center;}
}

@keyframes zoomOutRight {
  40% {opacity: 1;-webkit-transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0);transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0);}to {opacity: 0;-webkit-transform: scale(.1) translate3d(2000px, 0, 0);transform: scale(.1) translate3d(2000px, 0, 0);-webkit-transform-origin: right center;transform-origin: right center;}
}

.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes zoomOutUp {
  40% {opacity: 1;-webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);}to {opacity: 0;-webkit-transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);-webkit-transform-origin: center bottom;transform-origin: center bottom;-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);}
}

@keyframes zoomOutUp {
  40% {opacity: 1;-webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);-webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);}to {opacity: 0;-webkit-transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);-webkit-transform-origin: center bottom;transform-origin: center bottom;-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);}
}

.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes slideInDown {
  from {-webkit-transform: translate3d(0, -100%, 0);transform: translate3d(0, -100%, 0);visibility: visible;}to {-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}
}

@keyframes slideInDown {
  from {-webkit-transform: translate3d(0, -100%, 0);transform: translate3d(0, -100%, 0);visibility: visible;}to {-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes slideInLeft {
  from {-webkit-transform: translate3d(-100%, 0, 0);transform: translate3d(-100%, 0, 0);visibility: visible;}to {-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}
}

@keyframes slideInLeft {
  from {-webkit-transform: translate3d(-100%, 0, 0);transform: translate3d(-100%, 0, 0);visibility: visible;}to {-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes slideInRight {
  from {-webkit-transform: translate3d(100%, 0, 0);transform: translate3d(100%, 0, 0);visibility: visible;}to {-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}
}

@keyframes slideInRight {
  from {-webkit-transform: translate3d(100%, 0, 0);transform: translate3d(100%, 0, 0);visibility: visible;}to {-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes slideInUp {
  from {-webkit-transform: translate3d(0, 100%, 0);transform: translate3d(0, 100%, 0);visibility: visible;}to {-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}
}

@keyframes slideInUp {
  from {-webkit-transform: translate3d(0, 100%, 0);transform: translate3d(0, 100%, 0);visibility: visible;}to {-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes slideOutDown {
  from {-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}to {visibility: hidden;-webkit-transform: translate3d(0, 100%, 0);transform: translate3d(0, 100%, 0);}
}

@keyframes slideOutDown {
  from {-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}to {visibility: hidden;-webkit-transform: translate3d(0, 100%, 0);transform: translate3d(0, 100%, 0);}
}

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes slideOutLeft {
  from {-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}to {visibility: hidden;-webkit-transform: translate3d(-100%, 0, 0);transform: translate3d(-100%, 0, 0);}
}

@keyframes slideOutLeft {
  from {-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}to {visibility: hidden;-webkit-transform: translate3d(-100%, 0, 0);transform: translate3d(-100%, 0, 0);}
}

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes slideOutRight {
  from {-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}to {visibility: hidden;-webkit-transform: translate3d(100%, 0, 0);transform: translate3d(100%, 0, 0);}
}

@keyframes slideOutRight {
  from {-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}to {visibility: hidden;-webkit-transform: translate3d(100%, 0, 0);transform: translate3d(100%, 0, 0);}
}

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}

@-webkit-keyframes slideOutUp {
  from {-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}to {visibility: hidden;-webkit-transform: translate3d(0, -100%, 0);transform: translate3d(0, -100%, 0);}
}

@keyframes slideOutUp {
  from {-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}to {visibility: hidden;-webkit-transform: translate3d(0, -100%, 0);transform: translate3d(0, -100%, 0);}
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
  animation-duration: 0.8s; 
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;    
  -webkit-animation-timing-function: ease;    
  visibility: visible !important;  
}
