// -------------------------
// BUTTONS
// -------------------------
button{
  background-color: transparent;
  padding: 0;
  border: 0px;
  color: #fff;
}
.btn{
  font-size: 12px;
  border: none;
  border-radius: initial;
  text-transform: uppercase;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
}
.btn-primary {
  color: #fff;
  background-color: $primary;
  &:hover, &:focus, &:active{
    color: #fff;
    background-color: #404040;
  }
}
.btn-secondary{
  font-size: 15px;
  font-family: $primary-Md;
  color: #fff;
  background-color: #B473B2;
  border-color: #B473B2;
}
.btn-subscription {
  position: absolute;
  bottom: 20%;
  left: 50%;
  height: 28px;
  width: 100px;
  margin: -14px 0 0 -50px;
  border-radius: initial;
}
.btn-block{    
  font-family: $primary-Bd;
  font-size: 16px;
  padding: 16px 0px;
}
.btn-block-sm{
  padding: 6px 60px;
}