
// -------------------------
// HOME
// -------------------------

body{
  font-family: 'Roboto Slab', serif;
  font-size: 17px;
  text-decoration: none;
  color: #5a5a5a;
  background-color: #f4f3f3;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  font-smooth: always;
  font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
h1,h2,h3,h4,h5{
  font-family: 'Roboto Slab', serif;
  font-size: 17px;
}
h1{
  font-size: 38px;
  color: $tertiary;
  font-family: 'Roboto Slab', serif;
}
p {
  line-height: 1.4;
  font-size: 17px;
  color: $tertiary;
  margin: 0;
  font-family: 'Lekton', sans-serif;
}
ul, ol{
  padding: 0;
}
a{
  text-decoration: none;
  &:focus{
    color: #404040;
  }
  &:hover{
    color: $primary;
  }
}
#page-content-wrapper{
  position: relative;
  display: none;
}

footer{
  position: relative;
  bottom: 0;
  right: 0;
  left: 0;
  .footer{
    .title,
    .subtitle{
      font-weight: 300;
      font-family: 'Roboto Slab', serif;
    }
    p{
      color: #acaaac;
      font-size: 13px;
      margin-top: 10px;
      font-family: 'Lekton', sans-serif;
      @include transition;
      &:hover{
        color: #fff;
      }
    }
    .title{
      font-size: 16px;
      color: #e0d5c1;
      margin-bottom: 20px;
    }
    .subtitle{
      color: #fff;
      font-size: 13px;
      margin-bottom: 10px;
      p{
        margin-top: 0px;
      }
      &:last-child{
        margin-bottom: 0px;
      }
    }
    .btn{
      font-family: 'Roboto Slab', serif;
    }
  }
}

@media (min-width: 768px) {
}

// HOME CONSTRUCTION

.main-banner--height{
  height: 300px;
  position: relative;
  .col-xs-12{
    position: initial;
  }
}
.main-banner{
  &.full-black{
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 300px;    
    background-color: #000;
    background-color: rgba(0, 0, 0, .5);
  }
  &.banner-01{
    height: 300px;
    background: url(../images/home-bcg--01.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    // background-attachment: fixed;
    @include background-size(cover);
  }
  &.banner-02{
    height: 300px;
    background: url(../images/home-bcg--02.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    // background-attachment: fixed;
    @include background-size(cover);
  }
  &.banner-03{
    height: 300px;
    background: url(../images/home-bcg--03.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    // background-attachment: fixed;
    @include background-size(cover);
  }
  &.banner-04{
    height: 300px;
    background: url(../images/benefits-bcg--01.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    // background-attachment: fixed;
    @include background-size(cover);
  }
  &.banner-05{
    height: 300px;
    background: url(../images/mundo-bcg--01.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    // background-attachment: fixed;
    @include background-size(cover);
  }
  &.banner-06{
    height: 300px;
    background: url(../images/elaboracion-bcg--01.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    // background-attachment: fixed;
    @include background-size(cover);
  }
  &.banner-07{
    height: 300px;
    background: url(../images/informes-bcg--01.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    // background-attachment: fixed;
    @include background-size(cover);
  }
  &.banner-08{
    height: 300px;
    background: url(../images/conocenos-bcg--01.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    // background-attachment: fixed;
    @include background-size(cover);
  }
  &.banner-09{
    height: 300px;
    background: url(../images/preguntas-bcg--01.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    // background-attachment: fixed;
    @include background-size(cover);
  }
  &.banner-10{
    height: 300px;
    background: url(../images/tipos-bcg--01.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    // background-attachment: fixed;
    @include background-size(cover);
  }
  &.banner-11{
    height: 300px;
    background: url(../images/links-bcg--01.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    // background-attachment: fixed;
    @include background-size(cover);
  }
  &.banner-12{
    height: 300px;
    background: url(../images/contacto-bcg--01.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    // background-attachment: fixed;
    @include background-size(cover);
  }
}

.banner-container { 
  width: 100%;
  position: absolute;
  z-index: 6;
  top: 50%;
  left: 50%;
  margin-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  @include transform(translate(-50%, -50%));
  .banner-txt {
    color: #fff;
    text-align: center;
    .banner-title {
      text-transform: uppercase;
      color: $cream;
      font-family: 'Roboto Slab', serif;
      font-weight: 300;
      font-size: 22px;
      line-height: 1.5;
    }
    .banner-subtitle {
      margin-top: 5px;
      font-family: 'Lekton', sans-serif;
      font-size: 16px;
    }
    .btn{
      margin-top: 20px;
    }
  }
}

.social-block {
  width: 100%;
  text-align: center;
  margin-top: 40px;
  a{
    margin-left: 40px;
    color: #404040;
    i{
      font-size: 1.5em;
    }
    &:hover{
      color: $primary;
    }
    &:nth-child(1){
      margin-left: 0;
    }
  }
}


.title{
  font-size: 26px;
  font-family: 'Roboto Slab', serif;
  text-transform: uppercase;
  color: $cream;
}
.products-container{
  text-align: center;
  .img-container{
    padding: 0px 30px;
    img{
      border-radius: 50%;
    }
  }
  .title{
    font-size: 26px;
    margin-top: 10px;
    line-height: 1.2;
  }
  p{
    margin-top: 10px;
    font-family: 'Lekton', sans-serif;
  }
  .btn{
    margin-top: 10px;
    font-family: 'Roboto Slab', serif;
  }
}
.slick-dots{
  bottom: -20px;
}

.height-calc{width: 100%;};

// HOME RESPONSIVE
//////////////////////////////////////////////////////////////////////////////////////////

@media (min-width: 768px) {
  .main-banner--height{
    height: 600px;
  }
  .main-banner{
    &.full-black{
      height: 600px;    
    }
    &[class^="banner-"],
    &[class*="banner-"] {
      height: 600px;
    }
  }
  .banner-container {
    padding: 15px 0px;
    margin-top: 0px;
    top: 50%;
    &.home{
      top: 70%;
    }
    .banner-txt {
      padding: 15px 0px;
      .banner-title {
        font-size: 50px;
        line-height: 1.5;
      }
      .banner-subtitle {
        font-size: 16px;
      }
    }
  }  
  .social-block {
    margin-top: 80px;
    a{
      margin-left: 40px;
    }
  }
  .slick-dots{
    bottom: -20px;
  }
}



// SECCION CONSTRUCTION
.fluid-txt--container {
  padding: 80px 0;
  p{
    max-width: 600px;
    text-align: center;
    margin: 0 auto;
    color: #fff;
    line-height: 2;
    span{
      color: $cream;
    }
  }
}
.half {
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 200px;
}
.half-right--txt, .half-left--txt {
  padding: 55px 90px 55px 30px;
}
.half-right--txt,
.half-left--txt {
  .title{
    font-size: 24px;
    margin-bottom: 20px;
  }
  p{
    color: #692d2b;
    letter-spacing: 1px;
    line-height: 2;
    font-size: 17px;
  }
}
.half-right--txt {
  padding: 55px 25px 55px;
}
.half-left--txt {
  padding: 55px 25px 55px;
}
.benefits-half--01{
  background: url(../images/benefits-half--01.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  @include background-size(cover);
}
.benefits-half--02{
  background: url(../images/benefits-half--02.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  @include background-size(cover);
}
.benefits-half--03{
  background: url(../images/benefits-half--03.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  @include background-size(cover);
}
.types-half--01{
  background: url(../images/tipos-half--01.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  @include background-size(cover);
}
.types-half--02{
  background: url(../images/tipos-half--02.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  @include background-size(cover);
}
.full-banner {
  width: 100%;
  &[class^="full-"],
  &[class*="full-"] {
    height: 450px;
  }
  &.full-01{
    background: url(../images/tipos-bcg--02.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    @include background-size(cover);
    background-attachment: fixed;
  }
  &.full-02{
    background: url(../images/tipos-bcg--03.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    @include background-size(cover);
    background-attachment: fixed;
  }
  &.full-03{
    background: url(../images/tipos-bcg--04.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    @include background-size(cover);
    background-attachment: fixed;
  }
  &.full-04{
    background: url(../images/elaboracion-bcg--02.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    @include background-size(cover);
    background-attachment: fixed;
  }
  &.full-05{
    background: url(../images/elaboracion-bcg--03.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    @include background-size(cover);
    background-attachment: fixed;
  }
  &.full-06{
    background: url(../images/elaboracion-bcg--04.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    @include background-size(cover);
    background-attachment: fixed;
  }
  &.full-07{
    background: url(../images/elaboracion-bcg--05.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    @include background-size(cover);
    background-attachment: fixed;
  }
  &.full-08{
    background: url(../images/conocenos-img--02.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    @include background-size(cover);
    background-attachment: fixed;
  }
}
.mundo-full--img{
  img{
    width: 100%;
  }
  p{
    color: #692d2b;
    letter-spacing: 1px;
    line-height: 2;
    font-size: 17px;
    text-align: center;
  }
}
.full-img--container {
  width: 100%;
  position: absolute;
  padding: 0 15px;
  top: 50%;
  left: 50%;
  text-align: center;
  @include transform(translate(-50%,-50%));
  .title{
    font-weight: 300;
    font-size: 24px;
  }
  p{
    color: #fff;
    margin-top: 20px;
  }
}
.report-txt{
  > p{
    font-size: 17px;
    > i{
      font-size: 16px;
      color: #a02631;
    }
  }
  > a{
    p{
      @include transition;
      margin-top: 5px;
      font-weight: 700;
      font-size: 14px;
      color: #a02631;
      > i{
        color: #dbcbad;
        font-size: 14px;
      }
      &:hover{
        color: #404040;
      }
    }
  }
}
.form-control{
  border-radius: 0px; 
  border: 1px solid #271a15;
  &:focus,
  &:hover{
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #271a15;
  }
}
.input-group-addon{
  background-color: #a02631;
  border: 1px solid #271a15;
  border-radius: 0;
  a{
    color: #fff;
    &:hover{
      color: #404040;
    }
  }
}

// SECCION RESPONSIVE
//////////////////////////////////////////////////////////////////////////////////////////

@media (min-width: 768px) {
  .half {
    height: 100%;
    position: absolute;
  }
  .half-right--txt {
    padding: 100px 10% 100px 30px;
  }
  .half-left--txt {
    padding: 100px 30px 100px 10%;
  }
  .full-img--container {
    padding: 0;
    width: initial;
    max-width: 650px;
    .title{
      font-size: 34px;
    }
  }
  .full-banner{
    &[class^="full-"],
    &[class*="full-"] {
      height: 550px;
    }
  }
}

