// -------------------------
// ACCORDION COLLAPSE
// -------------------------

.panel-group{
	.panel {
    border-radius: 0px;
    border: 0px solid transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-top: 10px !important;
    position: relative;
		i{
  		@include transition;
		}
		.panel-heading{
	    // height: 50px;
	    color: #404040;
	    background-color: #fff;
      padding: 14px 10px;
      border-top-left-radius: 0px;
	    border-top-right-radius: 0px;
      border-color: transparent;
      .circle-icon{
      	&.fa-stack{
					i:nth-child(1){
						color: #404040;
					}
					i:nth-child(2){
						color: #fff;
					}
      	}
      }
      .play-icon{
  	    position: absolute;
  	    right: 10px;
  	    top: 5px;
      	&.fa-stack{
					i:nth-child(1){
						color: $primary;
				    font-size: 1em;
		    		@include transform(rotate(180deg));
					}
      	}
      }
	    h4{
		    font-size: 16px;
        display: inline-block;
	    	width: 100%;
    		color: $cream;
    		@include transition;
	    }
		}
		.panel-collapse{
			.panel-body{
				overflow: hidden;
		    padding: 25px 15px;
		    border-top: 1px solid rgb(238, 238, 238);
		    .timer-container{
		    	display: inline-block;
		    	font-size: 18px;
    	    font-size: 20px;
			    margin-left: 15px;
		  		@include transition;
		    }
		    > i{
		    	font-size: 1.5em;
		    }
				.p,
				.fa-play{						
					opacity: 0;
	    		@include transform(translate(0px,-10px));
				}
			}
			&.in{
				.panel-body{
					.btn,
					.timer-container,
					.fa-play{
						opacity: 1;
		    		@include transform(translate(0px,0px));
					}
				}
			}
		}
		.collapsed{
    	h4{
		    color: #404040;
    	}
      .play-icon{
      	&.fa-stack{
					i:nth-child(1){
						color: #404040;
						opacity: 1;
		    		@include transform(rotate(0deg));
					}
      	}
      }
		}
	}
}

// COLLAPSE RESPONSIVE
//////////////////////////////////////////////////////////////////////////////////////////

@media (min-width: 768px) {
	.panel-group{
		.panel {
			.panel-heading{
		    &:hover{
		    	h4{
		    		color: $cream;
		    	}
		      .play-icon{
		      	&.fa-stack{
							i:nth-child(2){
								color: $primary;
							}
		      	}
		      }
	      }
			}
		}
	}
}
