// -------------------------
// FOOTER
// -------------------------

// Footer CONSTRUCTION
footer{
  min-height: 0px;
  background-color: #271a15;
  padding: 40px 30px 10px;
  .crj-terms{
    margin-top: 16px;
    margin-bottom: 15px;
    text-align: center;
    p{
      font-family: 'Lekton', sans-serif;
      color: #949494;
      font-size: 12px;
    }
  }
  .developed-thet{
    text-align: center;
    p{
      font-family: 'Lekton', sans-serif;
      display: inline-block;
      margin-right: 4px;
      font-size: 12px;
      text-transform: uppercase;
    }
    img{
      width: 35px;
      display: inline-block;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      margin-top: -2px;
    }
    a{
      color: #949494;
      &:hover, &:focus, &:visited{
        color: #fff;
      }
      p{
        color: #949494;
      }
      &:hover{
        p{
          color: white;
        }
        img{
          -webkit-filter: grayscale(0%); /* Chrome, Safari, Opera */
          filter: grayscale(0%);
        }
      }
    }
  }
}



// RESPONSIVE
@media (min-width: 768px){
	footer{
    // padding: 20px 20px 45px;
    &.padding-footer{
      padding: 20px 20px 44px;
    }
    .crj-terms{
      margin-bottom: 0px;
      float: left;
      a{
        font-size: 9px;
      }
    }
		.developed-thet{
			text-align: right;
		}
	}
}

