// -------------------------
// FONTS
// -------------------------

.font12{
	font-size: 12px;
}
.font13{
	font-size: 13px;
}
.font15{
	font-size: 15px;
}
.font17{
	font-size: 17px;
}
.font18{
	font-size: 18px;
}
.font19{
	font-size: 19px;
}
.font20{
	font-size: 20px;
}
.font21{
	font-size: 21px;
}
.font22{
	font-size: 22px;
}
.font23{
	font-size: 23px;
}
.font24{
	font-size: 24px;
}
.font25{
	font-size: 25px;
}
.font26{
	font-size: 26px;
}
.font27{
	font-size: 27px;
}
.font28{
	font-size: 28px;
}
.font29{
	font-size: 29px;
}
.font30{
	font-size: 30px;
}

.font78 {font-size:78px;line-height: 1.1;}
.font72 {font-size:72px;line-height: 1.1;}
.font52 {font-size:52px;line-height: 1.1;}
.font48 {font-size:48px;line-height: 1.1;}
.font42 {font-size:42px;line-height: 1.1;}
.font36 {font-size:36px;line-height: 1.1;}
.font32 {font-size:32px;line-height: 1.1;}
.font28 {font-size:28px;line-height: 1.1;}
.font26 {font-size:26px;line-height: 1.1;}
.font24 {font-size:24px;line-height: 1.1;}
.font20 {font-size:20px;line-height: 1.3;}
.font18 {font-size:18px;}
.font16 {font-size:16px;}
.font15 {font-size:15px;}
.font14 {font-size:14px;}
.font13 {font-size:13px;}
.font12 {font-size:12px;}
.font11 {font-size:11px;}
.font10 {font-size:10px;}
.font9  {font-size:9px;}
